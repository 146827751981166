.posts{
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    background-color: white;
    padding: 0.5em;
    border-radius: 5px;
    max-width: 1000px;
    /* box-shadow: -1px 0px 5px 5px #f4f5fa; */
}