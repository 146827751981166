.post__upVote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
	width:50px;
	height:50px;
	top: -5px;
	right: -40px;
    z-index: 1;
	background-color:#335DAD;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
    transition: all 0.1s ease-in-out;
    cursor: 'pointer'
}

.post__upVote:hover {
    box-shadow: 0 6px 14px 0 #666;
    transform: scale(1.05);
    
 }

 @media only screen and (max-width: 1024px) {

    .post__upVote {
        top: unset;
        left: 0;
        bottom: 0;
    }

    .itemDetail__upVote {
        top:unset;
        right: 5;
        bottom: 5;
    }

}

.itemDetail__upVote {
   display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
	width:50px;
	height:50px;
	right: 50px;
    z-index: 1;
	background-color:#335DAD;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
    transition: all 0.1s ease-in-out;
    cursor: 'pointer' 
}

