.header {
    font-size: small;
    font-family: 'Poppins', sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    margin: 0.5em 0 2.5em;
    position: sticky;
    top: 0;
    z-index: 100; /* this is optional and should be different for every project */
    /* box-shadow: -1px 0px 5px 5px #ffffff; */
    background-color: white;
}

.header__right {
    /* padding-right: 10px; */
}

.header__right ul{
    display: flex;
    align-items: center;
    list-style-type: none;
}

.header__right li {
    padding: 10px;
}

.header__right a{
    margin: 15px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    color: black;
}

.header__left {
    display: flex;
    align-items: center;
    padding-left: 100px;
}

.header__left h1 {
    color: #335DAD;
}

.header__link
{
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .container {
        padding: 0px;
        max-width: 400px;
    }
    .header {
        height: 200px;
    }
    .header__left {
        float: left;
        padding-left: 50px;
        display: block;
        display: flex;
        align-items: center;
        font-size: 8px;
    }

    .header__right ul.li {
      display: none;
    }
    .header__right ul.li.icon {
      float: right;
      display: block;
    }

    .header__right ul{
        float: right;
        display: block;
      }
  }
  
  @media screen and (max-width: 600px) {
    .header__right.responsive {
      position: relative;
    }
    .header__right.responsive ul.li.icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    .header__right.responsive ul.li.a {
      float: none;
      display: block;
      text-align: left;
    }
  }