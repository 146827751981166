.post {
    display: flex;
    align-items: center;
    margin: 1.5em 0 0 0;
    box-shadow: -1px 0px 5px 5px #f4f5fa;
    transition: all .2s;
    max-width: 100vw;
    position: relative;
}

.post:hover {
    transform: translate(-3px);
    box-shadow: 0 5px 10px rgb(0, 0, 0, 0.2);
}

.post:hover .post__hover{
    opacity: 0.9;
}

.post:hover .center__container {
    filter: opacity(25%);
}

.center__container {
    width: 150px;
    height: 150px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}

img {
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
  }

.post__hover {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    color: white;
    font-weight: bold;
    font-size: xx-large;
    opacity: 0;
    transition: opacity .4s ease;
    background-color: #5fa1e1;
    padding-top: 70px;
}

.post__left {
    width: 1000px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: left;
    align-items: center;
}

.post__left > a {
    /* display:block; */
    max-width:500px;
    word-wrap:break-word;
    white-space: normal
}

.post__left > i {
    font-size: large;
    color: #898ea2;
}

.post__left .post__timeStamp {
    /* font-weight: bold; */
    white-space: pre;
    text-decoration: none;
}

.left__timeHash {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.post__title {
    display: flex;
    justify-content: center;
    text-align: left;
    white-space: pre;
    text-decoration: none;
    color: black;
    white-space: pre;
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: xx-large;
}

.post > div {
    margin-right: 20px;
}

.post__center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
}

/* .post__upVote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
	width:50px;
	height:50px;
	top: -5px;
	right: -40px;
    z-index: 1;
	background-color:#335DAD;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
    transition: all 0.1s ease-in-out;
    cursor: 'pointer'
}

.post__upVote:hover {
    box-shadow: 0 6px 14px 0 #666;
    transform: scale(1.05);
    
 } */

.post__center > img {
    border-radius: 5px;
    height: auto;
}

.post__right {
    width: 85%;
}

.post__right > h3 a {
    font-weight: 600;
    font-size: medium;
    color: #464855;
    padding: 5px 0;
}

 @media only screen and (max-width: 600px) {

    .post__title {
        font-size: large;
    }

}