.itemDetail__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1.5em 1em;
    box-shadow: -1px 0px 5px 5px #f4f5fa;
}

.postinfo__timestamp{
    font-weight: bold;
}

.video {
    position: relative;
    background-color: white;
    scroll-snap-align: start;
    width: 100%;
    height: 100%;
}

.video__player {
    object-fit: fill;
    width: 100%;
    height: 100%;
}

.app__videos {
    position: relative;
    padding-top: 10px;
    padding-bottom: 50px;
    height: 500px;
    width: 90%;
    border-radius: 20px;
    max-width: 1000px;
}

.copy-area {
    padding: 10px;
}

.itemDetail__related {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.itemDetail__related > span{
    position: relative;
    font-weight: bold;
    font-size: large;
    max-width:500px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; */
}
.itemDetail__postinfo{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
 }

 .postinfo__title {
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: x-large;
    display: flex;
    justify-content: center;
    text-align: left;
    white-space: pre-line;
    padding-top: 20px;
 }

 .itemDetail__copyButton {
    display:inline-block;
    padding:0.35em 1.2em;
    border:0.1em solid #FFFFFF;
    margin:0 0.3em 0.3em 0;
    border-radius:0.12em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:300;
    color:#f8de87;
    background-color:#afaeae;
    text-align:center;
    transition: all 0.2s;
 }

 .itemDetail__copyButton:hover{
    color:#f8de87;
    background-color:#5a5a5a;
 }
